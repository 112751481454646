import logo from "../assets/mit.png";
import { Link } from "react-router-dom";
export default function Footer() {
  return (
<footer class="text-gray-700 body-font">
<div class="container px-5 py-2 mx-auto flex items-center sm:flex-row flex-col">
  <Link class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900" to="/">
  <img
      src={logo}
      className="w-12 h-18 text-white p-2"
      alt="Comunidad Misiones IT"
  />
    <span class="ml-3 text-xl text-mit-dark">Comunidad Misiones IT</span>
  </Link>
  <p class="text-sm sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4 text-mit">
    © 2022 - Todos los derechos reservados
  </p>
  <span class="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
  <p class="text-mit">Nuestras redes </p>
    <a target="_blank" href="https://twitter.com/ComunidadMIT" class="ml-3 text-mit-dark">
      <svg fill="#423565" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
        <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
      </svg>
    </a>
    <a target="_blank" href="https://www.instagram.com/comunidadmit/" class="ml-3 text-mit-dark">
      <svg fill="none" stroke="#423565" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
        <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
        <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
      </svg>
    </a>
    <a target="_blank" href="https://www.linkedin.com/company/comunidad-mit/" class="ml-3 text-mit-dark">
      <svg fill="#423565" stroke="#423565" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" class="w-5 h-5" viewBox="0 0 24 24">
        <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
        <circle cx="4" cy="4" r="2" stroke="none"></circle>
      </svg>
    </a>
  </span>
</div>
</footer>
  );
}